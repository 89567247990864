/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


 /* Backward compatibility with CSS grid */
/* autoprefixer grid: autoplace */

/* Share Buttons */
// @import '~ngx-sharebuttons/themes/default.scss';

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Import Handsontable */
@import '~handsontable/dist/handsontable.full.min.css';

/* Inter Fonts */
@import url('https://fonts.googleapis.com/css?family=Inter:100,100i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

/* To make sure the scrollbar does not push content to the left */
ion-content::part(scroll) {
    overflow-y: overlay;
}
hot-table {
    font-size: 10px;
}
.cc-revoke {
    display: none;
}
.svgmenufixedsize {
    svg {
        height: 2.8rem;
        width: 2.8rem;
    }
}
.dataHandsonTable {
    position: relative;
    z-index: 0;
    }

.htUIInput input {
    @media (prefers-color-scheme: dark) {
        background-color: white;
        color: black;
    }
}

div#hubspot-messages-iframe-container iframe {
    @media screen and (max-width: 768px) {
        height: 85%!important;
        bottom: 45px!important;
    }
}

.tabs__panel { background-color: unset!important }
.tabs__tab { 
    background-color: var(--ion-background-color)!important;
    @media screen and (max-width: 768px) { 
        font-size: 10px;
    }
}

.nucampheader{
    @media screen and (max-width: 768px) {
        height: 500px;
        background-size: 640px 500px;
        align-items: flex-end;
    }
    @media screen and (max-height: 560px) {
        align-items: center;
    }
    background-size: cover;
    height: 600px; 
    align-items: center;
    .subheader{
        height: 100%;
        text-align: left;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        @media screen and (min-width: 769px){
            justify-content: center;
        }
        @media screen and (max-width: 768px){
            margin: 0 0 0 1rem;
            justify-content: flex-end;
        }
        @media screen and (max-height: 600px){
            padding-bottom: 4rem!important;
            justify-content: flex-end;
        }
        button{
            font-size: 2rem;
            height: 5rem;
        }
    }
}
.H1Home {
    @media screen and (max-width: 768px) {
        font-size: 3rem;
        line-height: 4rem;
        //margin: 0rem 2rem 0rem 0rem;
    }
    color: rgb(220, 220, 220);
    font-size: 5.5rem;
    line-height: 8rem;
    font-weight: 600;
}
.SecondH1Home {
    @media screen and (max-width: 768px) {
        font-size: 2.6rem;
        line-height: 3.6rem;
        margin: 0rem 1rem 2rem 0rem;
    }
    color: white;
    margin: 2rem 0 3rem 0rem;
    font-size: 3.2rem;
    line-height: 5rem;
    font-weight: 300;
}
.H3Home{
    @media screen and (max-width: 768px) {
        font-size: 3.5rem;
    }
    font-family: 'Inter', Black;
    font-weight: 900;
    font-size: 4.5rem;
    padding-top: 1rem;
}
.H4Home{
    @media screen and (max-width: 768px) {
        font-size: 2.5rem;
        font-weight: 400;
    }
    font-family: 'Inter', Black;
    font-size: 3rem;
    font-weight: 900;
}
.H2Home{
    @media screen and (max-width: 768px) {
        font-size: 2.2rem;
        line-height: 3.6rem;
        margin: 0rem 1rem 2rem 0rem;
    }
    color: white;
    margin: 2rem 0 3rem 0rem;
    font-size: 3.2rem;
    line-height: 5rem;
    font-weight: 300;
}

.PHome {
    font-weight: 400;
    @media screen and (max-width: 768px){
        font-size: 1.8rem;
        line-height: 3.0rem;
    }
    font-size: 2.2rem;
    line-height: 3.5rem;
}
.gridpadding{
    padding: 0;
}

.fxHidesx {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.fxHidesm {
    @media screen and (min-width: 769px) and (max-width: 1000px){
        display: none;
    }
}

.fxHidegtmd {
    @media screen and (min-width: 1280px) {
        display: none;
    }
}
.fxHidelg {
    @media screen and (min-width: 1300px) and (max-width: 1919px) {
        display: none;
    }
}
.fxHideltlg {
    @media screen and (max-width: 1299px) {
        display: none;
    }
}
.dark {
    color: var(--ion-text-dark);
}

.studenContractInputFix {
    input {
        margin: 12px 6px 6px 6px;
        padding: 8px!important;
        height: 45px;
        box-shadow: 0 1px 3px 1px #cfd7df!important;;
    }
}
.button-square-left {
    ion-button {
        --border-radius:  5px 14px 14px 5px;
    }
}
.button-square-right {
    ion-button {
        --border-radius:  14px 5px 5px 14px;
    }
}
.native-textarea.sc-ion-textarea-md {
    overflow: auto!important;
}
.nucamp-forms-grey {
    ion-item {
        --background:var(--ion-background-color);
    }
    ion-input {
        --background: var(--ion-backgroundgrey-color);
    }
    ion-textarea {
        --background:var(--ion-backgroundgrey-color);
    }
    ionic-selectable {
        .ionic-selectable-value {
            background-color: var(--ion-backgroundgrey-color);
        }
    }
    ion-select {
        background: var(--ion-backgroundgrey-color);
    }
}
.underline {
    text-decoration: underline;
    text-underline-offset: 2px;
}
.nucamp-forms-white {
    ion-item::part(native) {
        background-color: var(--ion-backgroundgrey-color);
    }
    ion-input {
        --background:var(--ion-background-color);
    }
    ion-textarea {
        --background:var(--ion-background-color);
    }
    ionic-selectable {
        .ionic-selectable-value {
            background-color: var(--ion-background-color);
        }
    }
    ion-select {
        background: var(--ion-background-color);
    }
}

.python-instructors-alert {
    --min-height: 75%;
    .alert-message.sc-ion-alert-md {
        max-height: 100%;
    }
}

.ionic-selectable-modal {
    border: 4px var(--ion-color-dark) solid;
    // border-radius: 15px;
}
  
.nucamp-forms {
    .item-inner {
        padding-inline-end: unset;
    }
    ion-item-divider {
        --inner-padding-start: 0px;
        --inner-padding-end: 0px;
        --padding-end: 0px;
        --padding-start: 0px;
    }
    ion-item {
        --padding-end: 0px;
        --padding-start: 0px;
        --inner-padding-end: 0px;
        --inner-padding-start: 0px;
        --background-focused: unset;
        --background-focused-opacity: unset;
    }
    ion-input {
        --padding-top: 5px!important;
        --padding-end: 8px!important;
        --padding-bottom: 5px!important;
        --padding-start: 8px!important;
        font-size: 14px;
    }

    ion-text {
        p {
            font-size: 18px;
            font-weight: bold;
        }
    }
    ion-label {
        margin-bottom: 8px!important;
    }
    ion-textarea {
        --padding-top: 5px!important;
        --padding-end: 8px!important;
        --padding-bottom: 5px!important;
        --padding-start: 8px!important;
    }
    .before-two-line-label {
        z-index: 10;
    }
    .two-line-label {
        position: relative;
        top: -12px;
        ion-input {
            margin-top: 4px;
        }
    }
    ionic-selectable {
        .ionic-selectable-value {
            font-size: 14px;
            padding: 6px 0px 0px 8px;
        }
        .ionic-selectable-inner {
            height: 30px;
        }
    }
    ion-select {
        --padding-top: 5px!important;
        --padding-end: 8px!important;
        --padding-bottom: 5px!important;
        --padding-start: 8px!important;
        font-size: 14px;
    }

}
// .modal-wrapper.sc-ion-modal-md {
//     box-shadow: 0px 0px 5px 5px var(--ion-backgroundgrey-color);
//     transition: all 200ms ease-out;
// }
// .modal-wrapper.sc-ion-modal-ios {
//     box-shadow: 0px 0px 5px 5px var(--ion-backgroundgrey-color);
//     transition: all 200ms ease-out;
// }

//ie11 CSS Patch
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10 & 11 */
    .click-block { // IE doesn't like this click-block, so hide it
        display: none;
    }

    // give our form elements some height since IE doesn't support the Ionic code that takes care of this
    input, ion-label {
        min-height: 1em;
    }

    ion-input, ion-textarea {
        min-height: 2em;
    }
}

ion-toast::part(container) {
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.toolbar-md{
    min-height: 51px;
    height: 51px
}
.toolbar-ios{
    min-height: 51px;
    height: 51px
}
.toolbar-md{
    min-height: 51px;
    height: 51px
}
.notfilled{
    .item-inner{
        border-bottom: 2px solid red!important;
    }
}
a{text-decoration:none}

//remove the scroll-bar on mobile phone emulators
.scroll-content {
    overflow-y: auto!important;
    margin-top: 51px;
}
.alert-title {
    font-size: 26px!important;
    @media screen and (max-width: 576px) {
        font-size: 24px!important;
    }
}
.flat-ul {
    ul {
        padding-inline-start: 0px;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}
.alert-checkbox-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
  
.alert-checkbox-label.sc-ion-alert-ios {
    white-space: pre-line !important;
}
.alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
  
.alert-radio-label.sc-ion-alert-ios {
    white-space: pre-line !important;
}
.sc-ion-alert-md-h {
    @media screen and (min-width: 0px) and (max-width: 768px) {
        --max-width: 340px;
        --min-width: 320px;
    }
    @media screen and (min-width: 769px) {
        --max-width: 500px;
        --min-width: 480px;
    }
}
.sc-ion-alert-ios-h {
    @media screen and (min-width: 0px) and (max-width: 768px) {
        --max-width: 340px;
        --min-width: 320px;
    }
    @media screen and (min-width: 769px) {
        --max-width: 500px;
        --min-width: 480px;
    }
}

.nucamp-alert {
    @media screen and (min-width: 0px) and (max-width: 768px) {
        --max-width: 340px;
        --min-width: 320px;
    }
    @media screen and (min-width: 769px) {
        --max-width: 500px;
        --min-width: 480px;
    }
    text-overflow: ellipsis;
    flex: 1;
    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    li {
        margin-block-end: 0.5em;
    }
}

.nucamp-alert-loginpopup {
    @media screen and (min-width: 0px) and (max-width: 768px) {
        --max-width: 340px;
        --min-width: 320px;
    }
    @media screen and (min-width: 769px) {
        --max-width: 500px;
        --min-width: 480px;
    }
    text-overflow: ellipsis;
    flex: 1;
    p {
        display: block;
        margin: 8px 0 8px 0;
    }
    ol {
        margin: 8px;
    }
    li {
        margin-block-end: 8px
    }
    white-space: unset;
}

div.swiper-pagination {
    bottom:0px!important;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px
}

.menu-popover .popover-content{
    width: 200px !important;
};

.bootcamp-follow-modal {
    @media only screen and (max-width: 768px) {
        --height: 85%;
        --width: 80%;
    }
    @media only screen and (max-width: 500px) {
        --height: 90%;
        --width: 85%;
    }
    @media only screen and (max-width: 400px) {
        --height: 95%;
        --width: 95%;
    }
    @media only screen and (min-width: 768px) {
        --height: 650px;
        --width: 520px;
    }
}

.modal-border {
    .modal-wrapper {
        border: var(--ion-border) 5px solid;
    }
}

.wider-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        --height: 85%;
        --width: 70%
    }
}

.wider-modal-xl {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
          --height: 95%;
          --width: 95%
    }
}

.bootcampdetails-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) and (max-width: 1025px){
        --height: 80%;
        --width: 80%
    }
    @media only screen and (min-height: 600px) and (min-width: 1025px) {
        --height: 80%;
        --width: 60%
    }
}

.contract-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        --height: 85%;
        --width: 70%;
    }
}
.contract-modal-w9 {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        --height: 85%;
        --width: 90%;
    }
}

.admin-selection-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        --height: 80%;
        --width: 80%;
    }
}

.profile-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        --height: 85%;
        --width: 60%;
    }
}

.browsecommunities-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) and (max-width: 1025px){
        --height: 85%;
        --width: 80%
    }
    @media only screen and (min-height: 600px) and (min-width: 1025px) {
        --height: 85%;
        --width: 60%
    }
}

.modal-shadow {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4)!important;
    --backdrop-opacity:var(--ion-backdrop-opacity, 0.32)!important;
}

.default-modal {
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        --height: 85%;
        --width: 60%;
    }
}

// .modal-wrapper {
//     @media only screen and (min-height: 600px) and (min-width: 768px) {
//       width: 70% !important;
//       left: calc(50% - (70%/2));
//     }
//   }
page-popselector {
    height: 100%;
}
.selector-popover-interestedbootcamp {
    @media screen and (max-width: 500px) {
        --width: 90%;
        --height: 80%;
        .popover-content {
            left: calc(50% - (90%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 501px) and (max-width: 600px) {
        --width: 90%;
        --height: 55%;
        .popover-content {
            left: calc(50% - (90%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 601px) and (max-width: 900px) {
        --width: 70%;
        --height: 55%;
        .popover-content {
            left: calc(70% - (70%/2))!important;
        }
        // overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
    @media screen and (min-width: 901px) {
        --width: 50%;
        --height: 55%;
        .popover-content {
            left: calc(50% - (50%/2))!important;
        }
        // overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
}
.selector-popover-sm {
    @media screen and (max-width: 500px) {
        --width: 90%;
        --height: 50%;
        .popover-content {
            left: calc(50% - (90%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 501px) {
        --width: 50%;
        --height: 50%;
        .popover-content {
            left: calc(50% - (50%/2))!important;
        }
        // overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
};
.sendtext-popover {
    @media screen and (max-width: 500px) {
        --width: 90%;
        --height: 50%;
        .popover-content {
            left: calc(50% - (90%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 501px) {
        --width: 33%;
        --height: 33%;
        .popover-content {
            left: calc(33% - (33%/2))!important;
        }
        // overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
}
.selector-popover {
    @media screen and (max-width: 500px) {
        --width: 95%;
        --height: 80%;
        .popover-content {
            left: calc(50% - (95%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 501px) and (max-width: 1020px){
        --width: 75%;
        --height: 80%;
        .popover-content {
            left: calc(50% - (75%/2))!important;
        }
        //overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
    @media screen and (min-width: 1021px) {
        --width: 50%;
        --height: 80%;
        .popover-content {
            left: calc(50% - (50%/2))!important;
        }
        //overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
    @media screen and (min-height: 1000px) and (max-height: 1400px) and (min-width: 501px) and (max-width: 1025px){
        --width: 75%;
        --height: 60%;
        .popover-content {
            left: calc(50% - (75%/2))!important;
        }
        //overflow: hidden;
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
};

.selector-popover-business {
    @media screen and (max-width: 768px) {
        --width: 95%;
        --height: 85%;
        .popover-content {
            left: calc(50% - (95%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 769px) {
        --width: 70%;
        --height: 90%;
        .popover-content {
            left: calc(50% - (70%/2))!important;
        }
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
};

.selector-popover-lg {
    @media screen and (max-width: 768px) {
        --width: 95%;
        --height: 85%;
        .popover-content {
            left: calc(50% - (95%/2))!important;
        }
        h2{
            font-size: 1.8rem;
        }
        h3{
            font-size: 1.7rem;
        }
    }
    @media screen and (min-width: 769px) {
        --width: 70%;
        --height: 90%;
        .popover-content {
            left: calc(50% - (70%/2))!important;
        }
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.8rem;
        }
    }
};
/* Colors */
.white { color: #fff; }
.blue { color: #0096dc; }
.purple { color: #a83ea2; }

.bluebg { background: #0096dc; }
.purplebg { background: #a83ea2; }

.whiteu { border-bottom: 3px solid #fff; }
.grayu { border-bottom: 3px solid gainsboro; }
.blueu { border-bottom: 2px solid #0096dc; }
.purpleu { border-bottom: 2px solid #a83ea2; }

//social sharing
.btn-facebook {color:#fff!important; background-color:#3b5998!important;}
.btn-google-plus{color:#fff!important;background-color:#dd4b39!important;}
.btn-youtube{color:#fff!important;background-color:#ff4b39!important;}
.btn-linkedin{color:#fff!important;background-color:#007bb6!important;}
.btn-twitter{color:#fff!important;background-color:#55acee!important;}
.btn-mail{color:#fff!important;background-color:#512DA8!important;}

// Fonts
.italic { font-style: italic; }
.fa-times {
    //background-color:map-get($colors, light); 
    color: white;
    font-size: 3rem;
    padding: 0 1rem 0 1rem;
}

ion-header {
    @media (prefers-color-scheme: dark) {
        background-color: lightslategray;
    }
    @media (prefers-color-scheme: light) {
        background-color: white;
    }
    
}
.tox .tox-promotion {
    display: none;
}
.background-shadow{
    background-color: rgba(var(--ion-color-nucamp-secondary),.70);
    @media screen and (max-height: 500px) {
        padding: 0rem 1rem 1rem 1rem;
        width: 100%;
    }
    @media screen and (min-height: 501px) and (max-width: 768px){
        padding: 0rem 1rem 1rem 1rem;
        width: 100%;
    }
    @media screen and (min-width: 769px) and (max-width: 1280px){
        width: 100%;
        padding: 3rem 5rem 3rem 5rem!important;
    }
    @media screen and (min-width: 1281px){
        width: 100%;
        padding: 3rem 15rem 3rem 10rem!important;
    }
}

.social{
    font-size: 3rem;
}

.big{
    font-size: 3rem;
}

.medium{
    font-size: 1.8rem;
}

.small{
    font-size: smaller;
}

.rowpadding{
    @media screen and (max-width: 768px) {
        padding: 0rem 1rem 0rem 1rem;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
        padding: 0rem 2rem 0rem 2rem;
    }
    @media screen and (min-width: 1025px) and (max-width: 1200px){
        padding: 0rem 2rem 0rem 2rem;
    }
    padding: 0rem 10rem 0rem 10rem;
}
.href{
    width: fit-content;
    cursor: pointer;
    color: var(--ion-color-primary);
}
.borderbottom {
    border-bottom: 1px dotted;
}
.href:hover {
    -webkit-filter: brightness(115%); /* Safari 6.0 - 9.0 */
    filter: brightness(115%);
}
.whitehref{
    width: fit-content;
    cursor: pointer;
    color: white;
    border-bottom: 1px white dotted;
}
.whitehref:hover {
    -webkit-filter: brightness(115%); /* Safari 6.0 - 9.0 */
    filter: brightness(115%);
}
.HH {
    display: none;
}
.hover{
    cursor: pointer;
}
.myheader{
    ion-buttons{
        margin: 0 0.2rem 0 1rem;
    }
}

.expanded-image-video {
    img{
        width: auto;
        @media screen and (max-width: 768px) {
            max-height: 200px;
        }
        @media screen and (min-width: 768px) {
            max-height: 400px;;
        }
    }
    video{
        width: 100%;
    }
}

.modaltitle { 
    padding-inline-end: 25px;
    H1{
        text-transform: uppercase; 
        color: white; 
        font-weight: 900;
        word-wrap: normal;
        white-space: normal;
        @media screen and (max-width: 768px) {
            font-size: 1.5rem
        }
        @media screen and (min-width: 768px) {
            font-size: 2.3rem
        }
        margin: 0;
    }
    H5{
        text-transform: uppercase; 
        color: white; 
        font-weight: 900; 
    }
    // background-color:map-get($colors, light) 
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    html {
        font-family: 'Inter';
        font-size: 45%;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    html {
        font-family: 'Inter';
        font-size: 55%;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    html {
        font-family: 'Inter';
        font-size: 58%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
    html {
        font-family: 'Inter';
        font-size: 60%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
    html {
        font-family: 'Inter';
        font-size: 62.5%;
    }
}

@media screen and (min-width: 1200px){
    html {
        font-family: 'Inter';
        font-size: 65%;
    }
}

/*===============================================

General Animations



/* NEW NUCAMP */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, blockquote, p, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, 
sup, tt, var, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td { 
    // border: 0; 
    font-family: inherit; 
    font-size: 100%; 
    font-style: inherit; 
    font-weight: inherit; 
    margin: 0; 
    outline: 0; 
    padding: 0; 
    vertical-align: baseline; 
}

strong{
    font-weight:bold;
}

.nucamp-centered-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.prerequesites-li {
    li {margin: 0px 0 10px 20px;}
}
.nucamp-centered-right-flex {
    display: flex;
    align-items: center;
    justify-content:flex-end;
}
.nucamp-centered-left-flex {
    display: flex;
    align-items: center;
    justify-content:flex-start;
}
.nucamp-centered-space-between-flex {
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.nucamp-top-left-flex {
    display: flex;
    align-items:flex-start;
    justify-content:flex-start;
}
.nucamp-swiper-navigation {
    position: relative;
    top: -20px;
}
.nucamp-swiper-button-prev, .nucamp-swiper-button-next {
    height: 20px;
    z-index: 10;
    font-size: 20px;
    cursor: pointer;
}

img { 
    border: 0px; 
}

:root { 
	--primary-color: #0096dc; 
}

:focus {
    outline: 0; 
}

* { 
    -webkit-text-size-adjust: none;  
    box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    -ms-box-sizing: border-box;
}

// input[type=text], input[type=email], input[type=password], textarea, ul { 
//     -webkit-appearance: none; 
//     -webkit-margin-before:0; 
//     -webkit-margin-after: 0; 
//     -webkit-margin-start: 0; 
//     -webkit-margin-end: 0; 
//     -webkit-padding-start: 0; 
// }

html { 
    height:100%; 
} 

body { 
    height: 100%; 
    font-size: 14px; 
    font-family: Inter, Helvetica, Arial, Sans-serif; 
    color: var(--ion-text-dark); 
    line-height: 20px; 
    margin: 0; 
    background: #fff;  
}

.clearfix:after { 
    content: "."; 
    visibility: hidden; 
    display: block; 
    height: 0; 
    clear: both; 
}
.clearright { 
    clear: right; 
}


/* TEXT */
h1, h2, h3, h4, h5, h6 {
    -webkit-font-smoothing: antialiased; 
    line-height: 1.2em; 
    letter-spacing: -.04em; 
    font-weight: 700; 
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
h1 {
    font-size: 3.6rem;
    @media screen and (max-width: 1200px) {
        font-size: 3.2rem;
    }
    @media screen and (max-width: 992px) {
        font-size: 3.0rem;
    }
    @media screen and (max-width: 768px) {
        font-size: 2.8rem;
    }
    @media screen and (max-width: 576px) {
        font-size: 2.6rem;
    }
}

h3 {
    font-size: 3.6rem;
    @media screen and (max-width: 1200px) {
        font-size: 3.4rem;
    }
    @media screen and (max-width: 992px) {
        font-size: 3.2rem;
    }
    @media screen and (max-width: 768px) {
        font-size: 3rem;
    }
    @media screen and (max-width: 576px) {
        font-size: 2.8rem;
    }
}

h4 {
    font-size: 24px;
    @media screen and (max-width: 1200px) {
        font-size: 22px;
    }
    @media screen and (max-width: 992px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}

h5 {
    font-size: 20px;
    @media screen and (max-width: 1200px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}

.dark {
    color: var(--ion-text-dark);
}

a {
    text-decoration: none;
    cursor: pointer;
}


.content {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7em;
    @media screen and (min-width: 768px) and (max-width: 1018px) {
        font-size: 14px;
    }
}

.highlightPurple {
    // background: linear-gradient(to left, #d939b1, #a83ea2);    
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: #a83ea2;
    @media (prefers-color-scheme: dark) {
        // background: linear-gradient(to left, #f279d2, #ff55e2);
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color:  #ff55e2;
    }
}
.hoverHighlightPurple:hover {
    // background: linear-gradient(to left, #d939b1, #a83ea2);    
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    border-bottom: 3px solid #a83ea2;
    @media (prefers-color-scheme: dark) {
        // background: linear-gradient(to left, #f279d2, #ff55e2);
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        border-bottom: 3px solid #ff55e2;
    }
}

.highlightPink {
    color: #d8276b;
    // background: linear-gradient(to left, #ec6c78, #d8276b);    
    // -webkit-background-clip: text;
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
    @media (prefers-color-scheme: dark) {
        color: #ff5597;
        // background: linear-gradient(to left, #f27684, #ff5597);
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }
}

.highlightBlue {
    color: #07D2FF;
    // background: linear-gradient(to right, #0099DE 0%, #07D2FF 100%);
    // -webkit-background-clip: text;
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
    @media (prefers-color-scheme: dark) {
        color: #55caff;
    //     background: linear-gradient(to left, #66e4ff, #55caff);
    //     background-clip: text;
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    }
}

/* STRIPE */
ion-grid, ion-col, ion-row {
    padding: 0;
    margin: 0
}

.header-md::after {
    display: none;
}

.alert-message::-webkit-scrollbar, .alert-message::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-message::-webkit-scrollbar-track, .alert-message::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-message::-webkit-scrollbar-thumb, .alert-message::-webkit-scrollbar-thumb {
    background-color: var(--ion-text-dark);
    outline: 1px solid var(--ion-text-dark);
}

.alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: var(--ion-text-dark);
    outline: 1px solid var(--ion-text-dark);
}
.greyBox {
    background: var(--ion-backgroundgrey-color);
    padding: 50px 40px;
    @media screen and (max-width: 1200px) {
        padding: 50px 30px;
    }
    @media screen and (max-width: 992px) {
        padding: 40px 30px;
    }
    @media screen and (max-width: 768px) {
        padding: 30px;
    }
    @media screen and (max-width: 576px) {
        padding: 30px 0;
    }
}
.stripe-title {
    padding: 0 40px;
    @media screen and (max-width: 576px) {
        padding: 0;
    }
    @media screen and (min-width: 576px) and (max-width: 992px) {
        padding: 0 30px;
    }
}
.stripe-subtitle {
    margin-top: 20px; 
    margin-bottom: 30px; 
    line-height: 1.4em; 
    color: #737782;
    @media (prefers-color-scheme: dark) {
        color: #999ba4;
    }
    font-weight: 300;
    @media screen and (max-width: 576px) {
        font-size: 18px;
    }
    @media screen and (min-width: 577px) and (max-width: 768px) {
        font-size: 18px;
    }
    @media screen and (min-width: 769px) and (max-width: 992px) {
        font-size: 20px;
    }
    @media screen and (min-width: 992px) {
        font-size: 22px;
    }
}
.stripe-subtitle-p {
    font-size: 16px;
    line-height: 1.7;
    color: var(--ion-color-dark);
    font-weight: 400;
}
.component-padding-sm {
    @media screen and (max-width: 1018px) {
        padding-left: 16px!important;
        padding-right: 16px!important;
    }
}
ion-card {
    -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
}
.component-padding-vertical {
    padding-top: 100px;
}

.withCards {
    padding-left: 20px!important;
    padding-right: 20px!important;
    @media screen and (max-width: 1018px) {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    @media screen and (min-width: 1019px) and (max-width: 1200px) {
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
}
.stripe-inside {
    margin: auto;
    border: none;
    width: 100%;
    @media screen and (max-width: 576px) {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media screen and (min-width: 577px) and (max-width: 1018px) {
        padding-left: 16px;
        padding-right: 16px;
    }
    @media screen and (min-width: 1019px) and (max-width: 1200px) {
        width: 912px;
        padding-left: 30px;
        padding-right: 30px;
    }
    @media screen and (min-width: 1201px) {
        width: 1180px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

/* BUTTON */
ion-button {
    text-transform: none;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    --box-shadow: none;
    --border-radius: 17px;
    --padding-top: 10px;
    --padding-start: 20px;
    --padding-bottom: 10px;
    --padding-end: 20px;
}

.mdButton {
    height: 3.5em;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -.02em;
    --border-radius: 24px;
    --padding-top: 15px;
    --padding-start: 30px;
    --padding-bottom: 15px;
    --padding-end: 30px;
}

.communitybox { 
    --background: linear-gradient(to bottom, #e62972 0%,#d8276b 100%);    
}

.loginbox { 
    --background: linear-gradient(to bottom, #009ee8 0%,#0096dc 100%);
}

.sc-ion-popover-md-h {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0);
}

.nucamp-toolbar-noborder::after {
    left: unset;
    bottom: unset;
    background-position: unset;
    position: unset;
    width: inherit;
    height: unset;
    background-image: unset;
    background-repeat: unset;
    content: unset;
}

.nucamp-modal-content{
    padding: 0 20px 20px 20px;
}

ion-header {
    background: unset;
}

.nucamp-toolbar {
    padding: 20px;
    ion-toolbar {
        --background: unset;
        --padding-top: 0px;
        --padding-bottom: 0px;
        --padding-end: 0px;
        --padding-start: 0px;
    }
    ion-icon {
        width: 64px;
        height: 64px;
    }
    ion-title {
        padding-inline-start: 0px;
    }
    ion-button {
        --padding-top: 0px!important;
        --padding-bottom: 0px!important;
        --padding-end: 0px!important;
        --padding-start: 0px!important;
    }
    h1, h2 {
        padding-right: 0px;
        padding-left: 0px;
    }
}

  
/* OLD THEME */
.JobhuntingFont { color: #4CB216!important; }
.CyberfundamentalsFont { color: #ee2f25!important; }
.CareerservicesFont { color: #4CB216!important; }
.BeginnerFont{ color: #0096dc!important; }
.IntermediateFont{ color: #ff8700!important; }
.BackendFont{ color: #a83ea2!important; }
.AdvancedFont { color:#d8276b!important; }
.SoftwareEngineeringFont{
    color:#424247!important;
    @media (prefers-color-scheme: dark) {
        color:#cf4f80!important;
    }
}
.JobhuntingBorder { border: #4CB216 1px solid; }
.CyberfundamentalsBorder { border: #ee2f25 1px solid; }
.CareerservicesBorder { border: #4CB216 1px solid; }
.BeginnerBorder{ border: #0096dc 1px solid; }
.IntermediateBorder{ border: #ff8700 1px solid; }
.BackendBorder{ border: #a83ea2 1px solid; }
.AdvancedBorder { border:#d8276b 1px solid; }
.SoftwareEngineeringBorder{
    border:#424247 1px solid;
    @media (prefers-color-scheme: dark) {
        border:#cf4f80 1px solid;
    }
}

.CareerservicesB { background-color: #4CB216 }
.CyberfundamentalsB { background-color: #ee2f25 }
.JobhuntingB { background-color: #4CB216}
.BeginnerB { background-color: #0096dc}
.IntermediateB { background-color: #ff8700}
.BackendB { background-color: #a83ea2}
.AdvancedB { background-color: #d8276b}
.CareerservicesHoverB{:hover, :active, :focus { background-color: #4CB216}}
.JobhuntingHoverB{:hover, :active, :focus { background-color: #4CB216}}
.CyberfundamentalsHoverB{:hover, :active, :focus { background-color: #ee2f25}}
.BeginnerHoverB{:hover, :active, :focus { background-color: #0096dc}}
.IntermediateHoverB{:hover, :active, :focus { background-color: #ff8700}}
.BackendHoverB{:hover, :active, :focus { background-color: #a83ea2}}
.AdvancedHoverB{:hover, :active, :focus { background-color: #d8276b}}

.SoftwareEngineeringB { 
    background-color: #424247;
    @media (prefers-color-scheme: dark) {
        background-color:#cf4f80;
    }
}

.Beginner { background: #0096dc;
  background: -moz-linear-gradient(-45deg, #0096dc 0%, #07d3ff 100%);
  background: -webkit-linear-gradient(-45deg, #0096dc 0%,#07d3ff 100%);
  background: linear-gradient(135deg, #0096dc 0%,#07d3ff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0096dc', endColorstr='#07d3ff',GradientType=1 ); 
      #Beginner{
          color:#b3e7ff;
      }
}

.Cyberfundamentals { background: #ee2f25;
    background: -moz-linear-gradient(-45deg, #ee2f25 0%, #f0443b 100%);
    background: -webkit-linear-gradient(-45deg, #ee2f25 0%,#f0443b 100%);
    background: linear-gradient(135deg, #ee2f25 0%,#f0443b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee2f25', endColorstr='#f0443b',GradientType=1 ); 
        #Cyberfundamentals {
            color:#ef4a42;
        }
  }

.Intermediate { background: #ff8700;
  background: -moz-linear-gradient(-45deg, #ff8700 0%, #ffc226  100%);
  background: -webkit-linear-gradient(-45deg, #ff8700 0%,#ffc226  100%);
  background: linear-gradient(135deg, #ff8700 0%,#ffc226  100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F29200', endColorstr='#ffc226 ',GradientType=1 ); 
      #Intermediate{
          color:#f39d1a;
      }
}
 
.Backend { background: #a83ea2;
    background: -moz-linear-gradient(-45deg, #a83ea2 0%, #d939b1 100%);
    background: -webkit-linear-gradient(-45deg, #a83ea2 0%,#d939b1 100%);
    background: linear-gradient(135deg, #a83ea2 0%,#d939b1 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a83ea2', endColorstr='#d939b1',GradientType=1 ); 
        #Backend{
            color:#d939b1;
        }
  }

.Advanced { background: #d8276b;
  background: -moz-linear-gradient(-45deg, #d8276b 0%, #ec6c78 100%);
  background: -webkit-linear-gradient(-45deg, #d8276b 0%,#ec6c78 100%);
  background: linear-gradient(135deg, #d8276b 0%,#ec6c78 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8276b', endColorstr='#ec6c78',GradientType=1 ); 
      #Advanced{
          color:#ec6c78;
      }
}

.SoftwareEngineering { 
    background: #424247;
    background: -moz-linear-gradient(-45deg, #424247 0%, #555559 100%);
    background: -webkit-linear-gradient(-45deg, #424247 0%,#555559 100%);
    background: linear-gradient(135deg, #424247 0%,#555559 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#424247', endColorstr='#555559',GradientType=1 ); 
        #SoftwareEngineering{
            color:#ffffff;
        }
    @media (prefers-color-scheme: dark) {
        background: #cf4f80;
        background: -moz-linear-gradient(-45deg, #cf4f80 0%, #c96088 100%);
        background: -webkit-linear-gradient(-45deg, #cf4f80 0%,#c96088 100%);
        background: linear-gradient(135deg, #cf4f80 0%,#c96088 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf4f80', endColorstr='#c96088',GradientType=1 ); 
            #SoftwareEngineering{
                color:#ffffff;
            }
    }
}

.Careerservices { background: #4CB216;
    background: -moz-linear-gradient(-45deg, #4CB216 0%,#6DE62C  100%);
    background: -webkit-linear-gradient(-45deg, #4CB216 0%,#6DE62C  100%);
    background: linear-gradient(135deg, #4CB216 0%,#6DE62C  100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4CB216', endColorstr='#6DE62C ', GradientType=1 ); 
        #Careerservices{
            color:#4CB216;
        }
}

.Jobhunting { background: #4CB216;
    background: -moz-linear-gradient(-45deg, #4CB216 0%,#6DE62C  100%);
    background: -webkit-linear-gradient(-45deg, #4CB216 0%,#6DE62C  100%);
    background: linear-gradient(135deg, #4CB216 0%,#6DE62C  100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4CB216', endColorstr='#6DE62C ', GradientType=1 ); 
        #Jobhunting{
            color:#4CB216;
        }
}

.BeginnerToBackend {
    background: linear-gradient(135deg, #0096dc 0%,#a83ea2 100%);
}

.BeginnerToAdvanced {
    background: linear-gradient(135deg, #0096dc 0%,#d8276b 100%);
}

.BeginnerToIntermediate {
    background: linear-gradient(135deg, #0096dc 0%,#ff8700 100%);
}

.IntermediateToBackend {
    background: linear-gradient(135deg, #ff8700 0%,#a83ea2 100%);
}

.AdvancedToBackend {
    background: linear-gradient(135deg, #d8276b 0%,#a83ea2 100%);
}

.BackendToCareerservices {
    background: linear-gradient(135deg, #a83ea2 0%,#4CB216 100%);
}

.BackendToJobhunting {
    background: linear-gradient(135deg, #a83ea2 0%,#4CB216 100%);
}

.IntermediateToCareerservices {
    background: linear-gradient(135deg, #ff8700 0%,#4CB216  100%);
}

.IntermediateToJobhunting {
    background: linear-gradient(135deg, #ff8700 0%,#4CB216  100%);
}

.BackendToIntermediate {
    background: linear-gradient(135deg, #a83ea2 0%,#ff8700 100%);
}

.BackendToAdvanced {
    background: linear-gradient(135deg, #a83ea2 0%,#d8276b 100%);
}

.AdvancedToCareerservices { 
    background: linear-gradient(135deg, #d8276b 0%,#4CB216 100%);
}

.AdvancedToJobhunting { 
    background: linear-gradient(135deg, #d8276b 0%,#4CB216 100%);
}

.SoftwareEngineeringToCareerservices {
    background: linear-gradient(135deg, #424247 0%,#4CB216 100%);
}

.SoftwareEngineeringToJobhunting {
    background: linear-gradient(135deg, #424247 0%,#4CB216 100%);
}

// Financing Dropdown
.popover-content {
    --min-width: 40%;
}

.dropdownOptions .select-interface-option {
    font-size: 1.8rem; 
    font-weight: 500;
}


// Financing Comparison Table
.ngx-datatable {
    .datatable-header-cell {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: var(--ion-text-dark);
        padding: 20px;
        border-right: 1px solid var(--ion-border);
    }

    .datatable-body-cell {
        white-space: normal !important;
        border-right: 1px solid var(--ion-border);
    }
}

.ngx-datatable .datatable-header-cell:first-child, 
.ngx-datatable .datatable-header-cell:last-child { 
    border-right: 0; 
}

.ngx-datatable .datatable-body-cell:first-child, 
.ngx-datatable .datatable-body-cell:last-child { 
    border-right: 0; 
}

.ngx-datatable .datatable-header-cell:nth-child(2) { 
    border: 1px solid #d939b1; 
    border-top-left-radius: 5px; 
    border-top-right-radius: 5px; 
    border-bottom: 0;  
}

.ngx-datatable .datatable-body-cell:nth-child(2) { 
    border-left: 1px solid #d939b1; 
    border-right: 1px solid #d939b1; 
}

.ngx-datatable .data-row-wrapper:last-child .datatable-body-cell:nth-child(2) { 
    border-bottom: 1px solid #d939b1; 
    border-bottom-left-radius: 5px; 
    border-bottom-right-radius: 5px; 
}

.handsontable td {
    white-space: pre-line !important;
}

