// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /* Fonts and Font Sizes */
  --ion-font-family: Inter, Helvetica, Arial, Sans-serif;
  -webkit-font-smoothing: antialiased; 

  @media screen and (min-width: 0px) and (max-width: 340px) {
    font-size: 60%;
  }

  @media screen and (min-width: 340px) and (max-width: 480px) {
    font-size: 70%;
  }

  @media screen and (min-width: 480px) and (max-width: 768px){
    font-size: 65%;
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
      font-size: 67%;
  }

  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    font-size: 68%;
  }

  @media screen and (min-width: 1200px) and (max-width: 2200px) {
    font-size: 70%;
  }

  @media screen and (min-width: 2200px) {
    font-size: 85%;
  }

  /** primary **/
  --ion-color-primary: #0096dc;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** nucamp **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-nucamp-primary: #a83ea2;
  --ion-color-nucamp-primary-rgb: 168,62,162;
  --ion-color-nucamp-primary-contrast: #ffffff;
  --ion-color-nucamp-primary-contrast-rgb: 255,255,255;
  --ion-color-nucamp-primary-shade: #94378f;
  --ion-color-nucamp-primary-tint: #b151ab;

  --ion-color-nucamp-secondary: #173753;
  --ion-color-nucamp-secondary-rgb: 23,55,83;
  --ion-color-nucamp-secondary-contrast: #ffffff;
  --ion-color-nucamp-secondary-contrast-rgb: 255,255,255;
  --ion-color-nucamp-secondary-shade: #143049;
  --ion-color-nucamp-secondary-tint: #2e4b64;

  --ion-color-nucamp-danger: #f53d3d;
  --ion-color-nucamp-danger-rgb: 245,61,61;
  --ion-color-nucamp-danger-contrast: #ffffff;
  --ion-color-nucamp-danger-contrast-rgb: 255,255,255;
  --ion-color-nucamp-danger-shade: #d83636;
  --ion-color-nucamp-danger-tint: #f65050;
  
  --ion-color-nucamp-light: #0096dc;
  --ion-color-nucamp-light-rgb: 0,150,220;
  --ion-color-nucamp-light-contrast: #ffffff;
  --ion-color-nucamp-light-contrast-rgb: 255,255,255;
  --ion-color-nucamp-light-shade: #0084c2;
  --ion-color-nucamp-light-tint: #1aa1e0;

  --ion-color-nucamp-dark: #727277;
  --ion-color-nucamp-dark-rgb: 114,114,119;
  --ion-color-nucamp-dark-contrast: #ffffff;
  --ion-color-nucamp-dark-contrast-rgb: 255,255,255;
  --ion-color-nucamp-dark-shade: #646469;
  --ion-color-nucamp-dark-tint: #808085;

  --ion-color-nucamp-clear: #f2fbff;
  --ion-color-nucamp-clear-rgb: 242,251,255;
  --ion-color-nucamp-clear-contrast: #000000;
  --ion-color-nucamp-clear-contrast-rgb: 0,0,0;
  --ion-color-nucamp-clear-shade: #d5dde0;
  --ion-color-nucamp-clear-tint: #f3fbff;

  --ion-color-nucamp-gray: #808080;
  --ion-color-nucamp-gray-rgb: 128,128,128;
  --ion-color-nucamp-gray-contrast: #ffffff;
  --ion-color-nucamp-gray-contrast-rgb: 255,255,255;
  --ion-color-nucamp-gray-shade: #717171;
  --ion-color-nucamp-gray-tint: #8d8d8d;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  --ion-color-google: #ea4335;
  --ion-color-google-rgb: 234,67,53;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255,255,255;
  --ion-color-google-shade: #ce3b2f;
  --ion-color-google-tint: #ec5649;

  --ion-color-microsoft: #7FBA00;
  --ion-color-microsoft-rgb: 127,186,0;
  --ion-color-microsoft-contrast: #ffffff;
  --ion-color-microsoft-contrast-rgb: 255,255,255;
  --ion-color-microsoft-shade: #70a400;
  --ion-color-microsoft-tint: #8cc11a;

  --ion-color-twitter: #1DA1F2;
  --ion-color-twitter-rgb: 29,161,242;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #1a8ed5;
  --ion-color-twitter-tint: #34aaf3;

  --ion-color-yahoo: #720e9e;
  --ion-color-yahoo-rgb: 114,14,158;
  --ion-color-yahoo-contrast: #ffffff;
  --ion-color-yahoo-contrast-rgb: 255,255,255;
  --ion-color-yahoo-shade: #640c8b;
  --ion-color-yahoo-tint: #8026a8;

  --ion-color-github: #333333;
  --ion-color-github-rgb: 51,51,51;
  --ion-color-github-contrast: #ffffff;
  --ion-color-github-contrast-rgb: 255,255,255;
  --ion-color-github-shade: #2d2d2d;
  --ion-color-github-tint: #474747;

  --ion-color-linkedin: #0e76a8;
  --ion-color-linkedin-rgb: 14,118,168;
  --ion-color-linkedin-contrast: #EEEEEE;
  --ion-color-linkedin-contrast-rgb: 238,238,238;
  --ion-color-linkedin-shade: #0c6894;
  --ion-color-linkedin-tint: #2684b1;

  --ion-color-email: #B23121;
  --ion-color-email-rgb: 178,49,33;
  --ion-color-email-contrast: #e9e9e9;
  --ion-color-email-contrast-rgb: 255,255,255;
  --ion-color-email-shade: #9d2b1d;
  --ion-color-email-tint: #ba4637;

  --ion-color-nucamp-beginner: #0096dc;
  --ion-color-nucamp-beginner-rgb: 0,150,220;
  --ion-color-nucamp-beginner-contrast: #ffffff;
  --ion-color-nucamp-beginner-contrast-rgb: 255,255,255;
  --ion-color-nucamp-beginner-shade: #0084c2;
  --ion-color-nucamp-beginner-tint: #1aa1e0;
  
  --ion-color-nucamp-intermediate: #ff8700;
  --ion-color-nucamp-intermediate-rgb: 255,135,0;
  --ion-color-nucamp-intermediate-contrast: #ffffff;
  --ion-color-nucamp-intermediate-contrast-rgb: 255,255,255;
  --ion-color-nucamp-intermediate-shade: #d58000;
  --ion-color-nucamp-intermediate-tint: #ffc226;

  --ion-color-nucamp-backend: #a83ea2;
  --ion-color-nucamp-backend-rgb: 168,62,162;
  --ion-color-nucamp-backend-contrast: #ffffff;
  --ion-color-nucamp-backend-contrast-rgb: 255,255,255;
  --ion-color-nucamp-backend-shade: #94378f;
  --ion-color-nucamp-backend-tint: #b151ab;

	--ion-color-nucamp-cyberfundamentals: #ee2f25;
	--ion-color-nucamp-cyberfundamentals-rgb: 238,47,37;
	--ion-color-nucamp-cyberfundamentals-contrast: #ffffff;
	--ion-color-nucamp-cyberfundamentals-contrast-rgb: 0,0,0;
	--ion-color-nucamp-cyberfundamentals-shade: #d12921;
	--ion-color-nucamp-cyberfundamentals-tint: #f0443b;

  --ion-color-nucamp-advanced: #d8276b;
  --ion-color-nucamp-advanced-rgb: 216,39,107;
  --ion-color-nucamp-advanced-contrast: #ffffff;
  --ion-color-nucamp-advanced-contrast-rgb: 255,255,255;
  --ion-color-nucamp-advanced-shade: #be225e;
  --ion-color-nucamp-advanced-tint: #dc3d7a;

  --ion-color-nucamp-softwareengineering: #424247;
  --ion-color-nucamp-softwareengineering-rgb: 66,66,71;
  --ion-color-nucamp-softwareengineering-contrast: #ffffff;
  --ion-color-nucamp-softwareengineering-contrast-rgb: 255,255,255;
  --ion-color-nucamp-softwareengineering-shade: #3a3a3e;
  --ion-color-nucamp-softwareengineering-tint: #555559;

  --ion-color-nucamp-jobhunting: #4CB216;
  --ion-color-nucamp-jobhunting-rgb: 76,178,22;
  --ion-color-nucamp-jobhunting-contrast: #ffffff;
  --ion-color-nucamp-jobhunting-contrast-rgb: 255,255,255;
  --ion-color-nucamp-jobhunting-shade: #4CB216;
  --ion-color-nucamp-jobhunting-tint: #6DE62C;

  --ion-color-nucamp-red: #FF0000;
  --ion-color-nucamp-red-rgb: 255,0,0;
  --ion-color-nucamp-red-contrast: #ffffff;
  --ion-color-nucamp-red-contrast-rgb: 255,255,255;
  --ion-color-nucamp-red-shade: #e00000;
  --ion-color-nucamp-red-tint: #ff1a1a;

  --ion-background-color: #ffffff;
  --ion-backgroundgrey-color: #f6f7f8;
  --ion-backgroundwhite-color: #848baa;
  --ion-backgroundworkshop-color: #ffffff;
  --ion-swiper-background: #f8f9fa;
  --ion-topnavmenu-color1: #f8f9fa;
  --ion-topnavmenu-color2: #ffffff;
  --ion-text-dark: #444449;
  --ion-text-color: #444449;
  --ion-text-light: #f6f7f8;
  --ion-border: #e1e1e7
}

$customColors: (
  'white',
  'red',
  'nucamp-beginner', 
  'nucamp-intermediate', 
  'nucamp-backend', 
  'nucamp-advanced',
  'nucamp-softwareengineering',
  'nucamp-jobhunting',
  'nucamp-cyberfundamentals',
  'nucamp-primary', 
  'nucamp-secondary',
  'nucamp-danger', 
  'nucamp-light', 
  'nucamp-gray', 
  'nucamp-dark', 
  'nucamp-clear', 
  'facebook', 
  'google', 
  'microsoft', 
  'twitter', 
  'yahoo', 
  'github',
  'linkedin',
  'email'
  );
@each $color in $customColors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color}) !important;
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
    --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
    --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
  }
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #55caff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-nucamp-secondary: #0096dc;
    --ion-text-dark: #ebebf0;
    --ion-backgroundgrey-color: #252836;
    --ion-topnavmenu-color1: #252836;
    --ion-topnavmenu-color2: #1F1D2B;
    --ion-swiper-background: #2D303E;
    --ion-border: #323646;

    --ion-color-nucamp-softwareengineering: #cf4f80;
    --ion-color-nucamp-softwareengineering-rgb: 207,79,128;
    --ion-color-nucamp-softwareengineering-contrast: #ffffff;
    --ion-color-nucamp-softwareengineering-contrast-rgb: 255,255,255;
    --ion-color-nucamp-softwareengineering-shade: #b64671;
    --ion-color-nucamp-softwareengineering-tint: #d4618d;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-backgroundgrey-color: #252836;
    --ion-backgroundwhite-color: #f6f7f8;
    --ion-backgroundworkshop-color: #252836;
    --ion-topnavmenu-color1: #252836;
    --ion-topnavmenu-color2: #1F1D2B;
    --ion-background-color: #1F1D2B;
    --ion-background-color-rgb: 31, 29, 43;

    --ion-text-color: #ffffff;
    --ion-text-light: #252836;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #252836;
    --ion-item-background: #363a4b;
    --ion-swiper-background: #2D303E
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-backgroundgrey-color: #252836;
    --ion-backgroundwhite-color: #f6f7f8;
    --ion-backgroundworkshop-color: #252836;
    --ion-topnavmenu-color1: #252836;
    --ion-topnavmenu-color2: #1F1D2B;
    --ion-background-color: #1F1D2B;
    --ion-background-color-rgb: 31, 29, 43;

    --ion-text-color: #ffffff;
    --ion-text-light: #252836;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #363a4b;
    --ion-toolbar-background: #252836;
    --ion-tab-bar-background: #252836;
    --ion-swiper-background: #2D303E
  }
}